import React, { useEffect, useState } from "react";
import AnnouncementCard from "./AnnouncementCard";
import { useTranslation } from 'next-i18next';

const AnnouncementsList = () => {
  const [announcements, setAnnouncements] = useState([]);
  const { t, i18n } = useTranslation('common'); // Get translations from common.json

  // Fetch announcements from WordPress API based on current language
  useEffect(() => {
    const fetchAnnouncements = async () => {
      const languagePrefix = i18n.language; // Gets the current language (e.g., 'tj', 'ru', 'en')
      try {
        // Add a timestamp to bypass any caching issues
        const response = await fetch(`https://hotamiolymp.com/wp-json/custom/v1/announcements?lang=${languagePrefix}&timestamp=${Date.now()}`);
        if (response.ok) {
          const data = await response.json();
          console.log("Fetched data:", data); // Debugging to check API response
          setAnnouncements(data);
        } else {
          console.error("Failed to fetch announcements");
        }
      } catch (error) {
        console.error("Error fetching announcements:", error);
      }
    };

    fetchAnnouncements();
  }, [i18n.language]); // Refetch announcements on language change

  // Function to get the translated title based on the current language
  const getTranslatedTitle = (title) => {
    const languagePrefix = i18n.language; // Gets the current language (e.g., 'tj', 'ru', 'en')
    const regex = new RegExp(`${languagePrefix}\\$(.*?)($|\\s[\\w]{2}\\$)`, 'g'); // Match pattern with selected language
    const match = regex.exec(title);
    return match ? match[1] : title; // Return the match or original title if no match found
  };

  // Function to get the correct URL for the current language
  const getValidUrl = (url) => {
    const languagePrefix = i18n.language;
    // Split concatenated URLs and find the one matching the current language prefix
    const urls = url.split('-http').map((part, index) => index === 0 ? part : 'http' + part);
    const validUrl = urls.find(u => u.includes(`/${languagePrefix}/`));
    return validUrl || url; // Return the matched URL or the original if none matched
  };

  return (
    <section className="flex flex-col px-4 w-full text-lg leading-4 text-black">
      {announcements.length > 0 ? (
        announcements.map((announcement, index) => (
          <AnnouncementCard
            key={index}
            icon={announcement.image_url}
            title={getTranslatedTitle(announcement.title)} // Get translated title
            url={getValidUrl(announcement.url)} // Adjust URL based on language if necessary
          />
        ))
      ) : (
        <p style={{
          textAlign: 'center'
        }}> {t('announcement.noData')} </p>
      )}
      <h6 className="self-end mt-1 leading-none text-blue-900 cursor-pointer">
      </h6>
    </section>
  );
};

export default AnnouncementsList;
