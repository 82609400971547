import Image from "next/image";
import Link from "next/link";
import { useTranslation } from "next-i18next";

import logo from "../../public/images/logo/logo2.png";

import Nav1 from "./Nav1";
import { useAppContext } from "@/context/Context";



const MobileMenu = () => {
  const { mobile, setMobile } = useAppContext();

  const { t } = useTranslation('common');


  return (
    <>
      <div className={`popup-mobile-menu ${mobile ? "" : "active"}`}>

        <div className="inner-wrapper">
          <div className="inner-top">
            <div className="content">
              <div className="logo">
                <Link href="/">
                  <Image
                    src={logo}
                    alt="MJDRI Logo Images"
                  />
                </Link>
              </div>
              <div className="rbt-btn-close">
                <button
                  className="close-button rbt-round-btn"
                  onClick={() => setMobile(!mobile)}
                >
                  <i className="feather-x"></i>
                </button>
              </div>
            </div>
            <p className="description">
              {t('mobileMenu.title')}
            </p>
            <ul className="navbar-top-left rbt-information-list justify-content-start">
              <li>
                <Link href="mailto:hello@example.com">
                  <i className="feather-mail"></i>mjdri@mail.ru
                </  Link>
              </li>
              <li>
                <Link href="#">
                  <i className="feather-phone"></i>(+992) 553 - 30 - 17 - 17
                </Link>
              </li>
            </ul>
          </div>

          <Nav1 />

          <div className="mobile-menu-bottom">
            <div className="social-share-wrapper">
              <span className="rbt-short-title d-block"> {t('mobileMenu.social')} </span>
              <ul className="social-icon social-default transparent-with-border justify-content-start mt--20">
                <li>
                  <Link target="_blank" href="https://www.facebook.com/people/%D0%9C%D0%B0%D1%80%D0%BA%D0%B0%D0%B7%D0%B8-%D2%B7%D1%83%D0%BC%D2%B3%D1%83%D1%80%D0%B8%D1%8F%D0%B2%D0%B8%D0%B8-%D0%B4%D0%B0%D1%80%D1%91%D1%84%D1%82-%D0%B2%D0%B0-%D1%80%D1%83%D1%88%D0%B4%D0%B8-%D0%B8%D1%81%D1%82%D0%B5%D1%8A%D0%B4%D0%BE%D0%B4%D2%B3%D0%BE/61552628091411/">
                    <i className="feather-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link target="_blank" href="https://t.me/MDMJDRI">
                    <i className="feather-send"></i>
                  </Link>
                </li>
                <li>
                  <Link target="_blank" href="https://www.instagram.com/">
                    <i className="feather-instagram"></i>
                  </Link>
                </li>
                <li>
                  <Link target="_blank" href="https://www.youtube.com/">
                    <i className="feather-youtube"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
