import React from "react";
import Image from "next/image";
import AnnouncementsList from "./AnnouncementsList";
import { useTranslation } from 'next-i18next';


const AnnouncementsComponent = () => {
  const { t } = useTranslation('common'); // Get translations from common.json

  return (
    <article className="flex overflow-hidden flex-col bg-white rounded-2xl  border border-gray-200">
      <header className="flex gap-3 self-center mt-1 text-xl font-semibold text-blue-900 whitespace-nowrap">
        <Image
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/bb14e296c3f39059dc28ff8977d83011b76a80e43f0bc497e49e5f56e0fd46ef?apiKey=25daf50b519a42bba340a7cceb3e4c5c"
          alt=""
          height={100}
          width={100}
          className="object-contain shrink-0 aspect-[0.88] w-[38px]"
        />
        <h5 className="my-auto text-blue-900">{t('announcement.title')}</h5>
      </header>
      <hr
        style={{
          marginBottom: "0px",
        }}
        className="mt-2.5 w-full min-h-[1px]"
      />
      <AnnouncementsList />
    </article>
  );
};

export default AnnouncementsComponent;
