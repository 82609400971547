import { Flex } from 'antd';
import Image from 'next/image';
import React from 'react';

function SliderImage({ src, width, aspectRatio }) {
    return (
        <Image

            loading="lazy"
            src={src}
            alt=""
            className={`object-contain shrink-0`}
            height={1300}
            width={1500}
            style={{
                // display: 'flex',
                // justifyContent: 'space-evenly',
                width: "300px",
                height: 'auto',
                paddingRight: "10px",
                margin: "0px",
                aspectRatio,
            }}
        />
    );
}

export default SliderImage;