import React, { useEffect, useState } from "react";
import Image from "next/image";

const AnnouncementCard = ({ icon, title, url }) => {
  const [isCustomWidth, setIsCustomWidth] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // Check if the window width is between 790px and 950px
      setIsCustomWidth(window.innerWidth >= 790 && window.innerWidth <= 950);
    };

    // Set the initial state
    handleResize();

    // Add event listener to resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <a
      href={url}
      // target="_blank"
      rel="noopener noreferrer"
      className={`flex items-center p-2 mt-3 bg-white rounded-lg border border-gray-200 transition-transform duration-300 hover:scale-105 cursor-pointer ${isCustomWidth ? "flex-col" : "flex-row md:flex-col lg:flex-row"
        }`}
    // style={{
    //   display: 'flex',
    //   justifyContent: 'center',
    //   alignItems: 'center'
    // }}
    >
      <Image
        loading="lazy"
        src={icon}
        alt={title}
        height={100}
        width={100}
        className="object-contain shrink-0 self-center w-[47px]"
      />
      <h6
        style={{
          fontSize: "14px",
          display: 'flex',
          justifyContent: 'center',
          // alignItems: 'cen'
        }}
        className="text-center mt-4"
      >
        {title}
      </h6>
    </a>
  );
};

export default AnnouncementCard;
