import { useRouter } from "next/router";
import Link from "next/link";
import { useState, useEffect } from "react";

const Nav = () => {
    const [menuData, setMenuData] = useState([]);
    const [activeMenuItem, setActiveMenuItem] = useState("#");
    const [activeSubMenu, setActiveSubMenu] = useState(null);
    const [isMobileView, setIsMobileView] = useState(false);

    const router = useRouter();
    const { locale } = router;

    const loadMenuData = async (lang) => {
        const langFile = lang || "tj"; // Default to Tajik if no locale is provided
        try {
            const data = await import(`../../data/MegaMenu_${langFile}.json`);
            setMenuData(data.menuData);
        } catch (error) {
            console.error(`Error loading menu data for locale ${langFile}:`, error);
        }
    };

    useEffect(() => {
        loadMenuData(locale);
        setActiveMenuItem(null);
        setActiveSubMenu(null);
    }, [locale]);

    const toggleMenuItem = (item) => {
        setActiveMenuItem(activeMenuItem === item ? null : item);
        setActiveSubMenu(null);
    };

    const toggleSubMenuItem = (index) => {
        setActiveSubMenu(activeSubMenu === index ? null : index);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 1200);
        };

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                {/* Render menu items from JSON data */}
                {menuData.map((data, index) => (
                    <li key={index} className={`has-dropdown ${data.hasMenuChild ? "has-menu-child-item" : ""}`}>
                        <Link
                            href={data.menuType === "home" ? "/" : "#"}
                            onClick={(e) => {
                                if (data.menuType !== "home") {
                                    e.preventDefault();
                                    toggleMenuItem(data.menuType);
                                }
                            }}
                        >
                            {data.menuTitle}
                            {data.hasDropdown && <i className="feather-chevron-down"></i>}
                        </Link>

                        {/* Dropdown Menu */}
                        {data.menuItems && (
                            <ul className={`submenu ${activeMenuItem === data.menuType ? "active d-block" : ""}`}>
                                {data.menuItems.map((item, innerIndex) => (
                                    <li key={innerIndex} className="has-dropdown">
                                        <Link
                                            href={item.hasSubmenu ? "#" : item.link}
                                            onClick={(e) => {
                                                if (item.hasSubmenu) {
                                                    e.preventDefault();
                                                    toggleSubMenuItem(innerIndex);
                                                }
                                            }}
                                        >
                                            {item.title}
                                        </Link>

                                        {/* Submenu Items */}
                                        {item.hasSubmenu && item.submenuItems && (
                                            <ul className={`submenu ${activeSubMenu === innerIndex ? "d-block" : ""}`}>
                                                {item.submenuItems.map((submenuItem, submenuIndex) => (
                                                    <li key={submenuIndex}>
                                                        <Link href={submenuItem.link}>{submenuItem.title}</Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default Nav;
