import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/autoplay';
import SliderImage from './SliderImage';
import { useTranslation } from 'next-i18next';

const ImageSlider = () => {
  const { i18n } = useTranslation();

  // Define language-specific image arrays
  const images = {
    tj: [
      { src: "/images/sponsors/president_tj.png", url: "https://president.tj" },
      { src: "/images/sponsors/maorif_tj.png", url: "https://maorif.tj" },
      { src: "/images/sponsors/omuzgor_tj.png", url: "https://omuzgor-gazeta.tj" },
      { src: "/images/sponsors/xovar_tj.png", url: "https://khovar.tj" },
      { src: "/images/sponsors/edu-cip_tj.png", url: "https://edu-cip.tj" },
      { src: "/images/sponsors/takmil_tj.png", url: "https://takmil-ihtisos.tj" },
      { src: "/images/sponsors/mjtm_tj.png", url: "https://mjtm.tj" },
    ],
    ru: [
      { src: "/images/sponsors/president_ru.png", url: "https://president.ru" },
      { src: "/images/sponsors/maorif_ru.png", url: "https://maorif.ru" },
      { src: "/images/sponsors/omuzgor_ru.png", url: "https://omuzgor-gazeta.ru" },
      { src: "/images/sponsors/xovar_ru.png", url: "https://khovar.tj" },
      { src: "/images/sponsors/edu-cip_ru.png", url: "https://edu-cip.tj" },
      { src: "/images/sponsors/takmil_ru.png", url: "https://takmil-ihtisos.tj" },
      { src: "/images/sponsors/mjtm_ru.png", url: "https://mjtm.tj" },
    ],
    en: [
      { src: "/images/sponsors/president_en.png", url: "https://president.en" },
      { src: "/images/sponsors/maorif_en.png", url: "https://maorif.en" },
      { src: "/images/sponsors/omuzgor_en.png", url: "https://omuzgor-gazeta.en" },
      { src: "/images/sponsors/xovar_en.png", url: "https://khovar.tj" },
      { src: "/images/sponsors/edu-cip_en.png", url: "https://edu-cip.tj" },
      { src: "/images/sponsors/takmil_en.png", url: "https://takmil-ihtisos.tj" },
      { src: "/images/sponsors/mjtm_en.png", url: "https://mjtm.tj" },],
  };

  // Get the images array based on the current language
  const selectedImages = images[i18n.language] || images.tj;

  const handleClick = (url) => {
    window.open(url, '_blank');
  };

  return (
    <section className="pt-5 bg-white max-md:px-2.5">
      <Swiper
        slidesPerView="auto"
        freeMode={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        loop={true}
        modules={[FreeMode, Autoplay]}
        className="mySwiper"
      >
        {selectedImages.map((image, index) => (
          <SwiperSlide key={index} style={{ width: 'auto' }}>
            <div onClick={() => handleClick(image.url)} style={{ cursor: 'pointer' }}>
              <SliderImage
                src={image.src}
                width={700} // Adjust width here if needed
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default ImageSlider;
